'use client';

import LogoDark from '@job-ish/ui/assets/images/job-ish_logo_dark.webp';
import LogoLight from '@job-ish/ui/assets/images/job-ish_logo_light.webp';
import {m} from 'framer-motion';
import NextImage from 'next/image';

export type LogoProps = {
	colorMode?: 'dark' | 'light';
};

const Logo = ({colorMode}: LogoProps) => (
	<m.div animate={{opacity: 1}} initial={{opacity: 0}} transition={{duration: 0.25}}>
		<NextImage alt="job-ish" height={75} src={colorMode === 'dark' ? LogoDark : LogoLight} width={125} />
	</m.div>
);

export default Logo;
