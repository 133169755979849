'use client';

import {Anchor, IconButton, Separator, TextButton} from '@job-ish/ui/components';
import {IconBrandInstagram, IconBrandTiktok, IconBrandX} from '@tabler/icons-react';
import dayjs from 'dayjs';

export const JOB_BOARD_LINKS = [
	{
		href: 'https://app.job-ish.com/job-board?hasRemote=true',
		label: 'Remote Work Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?typeId=2&hasRemote=true',
		label: 'Remote Part Time Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?typeId=4&hasRemote=true',
		label: 'Remote Internships',
	},
	{
		href: 'https://app.job-ish.com/job-board?typeId=5&hasRemote=true',
		label: 'Remote Freelance Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22summer%5C%22%22%5D&typeId=2',
		label: 'Remote Summer Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22data+entry%5C%22%22%5D&hasRemote=true',
		label: 'Remote Data Entry Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22customer+service%5C%22%22%5D&hasRemote=true',
		label: 'Remote Customer Service Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22warehouse%5C%22%22%5D&typeId=2',
		label: 'Part Time Warehouse Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22software+engineer%5C%22%22%5D&experienceLevel=EN',
		label: 'Entry Level Software Engineer Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?companyIndustryId=81',
		label: 'Security jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?description=%5B%22%5C%22crypto%5C%22%22%5D',
		label: 'Web3 & Crypto Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?companyIndustryId=20',
		label: 'IT Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?companyIndustryId=154',
		label: 'Aviation Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?companyIndustryId=31',
		label: 'Construction Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?companyIndustryId=103',
		label: 'Fashion Jobs',
	},
	{
		href: 'https://app.job-ish.com/job-board?experienceLevel=EX',
		label: 'Executive jobs',
	},
];

const Footer = () => (
	<>
		<footer className="flex flex-col items-center gap-4 py-8">
			<div className="flex flex-col items-center justify-between gap-12 p-4">
				<div className="flex flex-col items-center gap-2">
					<div className="flex items-center gap-1.5 text-mauve11">
						<IconButton
							aria-label="Instagram"
							as="a"
							circular
							href="https://www.instagram.com/getjobish"
							icon={IconBrandInstagram}
							intent="ghost"
							rel="noopener noreferrer"
							target="_blank"
						/>
						<IconButton
							aria-label="TikTok"
							as="a"
							circular
							href="https://www.tiktok.com/@job.ish"
							icon={IconBrandTiktok}
							intent="ghost"
							rel="noopener noreferrer"
							target="_blank"
						/>
						<IconButton
							aria-label="X"
							as="a"
							circular
							href="https://x.com/job_ish"
							icon={IconBrandX}
							intent="ghost"
							rel="noopener noreferrer"
							target="_blank"
						/>
					</div>
					<span className="text-xs text-mauve11">© {dayjs().year()} job-ish. All rights reserved.</span>
				</div>
				<div className="flex items-center gap-3 text-xs">
					<TextButton
						as="a"
						className="font-semibold text-mauve11"
						href="https://job-ish.com/privacy-policy"
						size="xs"
						target="_blank"
						title="Privacy"
					>
						Privacy
					</TextButton>
					<Separator className="h-3" orientation="vertical" />
					<TextButton
						as="a"
						className="font-semibold text-mauve11"
						href="https://job-ish.com/terms"
						size="xs"
						target="_blank"
						title="Terms"
					>
						Terms
					</TextButton>
				</div>
			</div>
			<Separator className="my-8 w-full" />
			<div className="grid w-fit grid-cols-1 px-6 pb-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
				{JOB_BOARD_LINKS.map(({href, label}) => (
					<Anchor
						className="col-span-1 px-6 py-0.5 text-sm text-mauve11 visited:text-mauve11 sm:text-xs"
						href={href}
						key={href}
						target="_blank"
					>
						{label}
					</Anchor>
				))}
			</div>
		</footer>
	</>
);

export default Footer;
